import React from 'react';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import PopoverText from './PopoverText';
export default function InfoCardFor(props) {
  const handleToAgainst = (e) => {
    // e.preventdefault();
    props.handleCurrentTab('argAgainst');
  }

  const styleP = { textIndent: '.5rem' };
  return (
    <>
      <Card className="m-2">
        <Card.Title className="p-2" style={{ background: 'lightBlue' }}>Argument For the Measure</Card.Title>
        <h5 className="px-2" >Instructions: Click on <span><strong style={{ color: 'purple' }}>bold, purple</strong></span> text for arguments against the statement. </h5>
        <Card.Body>
          <p>Our students aren’t asking for a lot;<PopoverText isfootnoteSet={true} header="Our Students DO deserve quality education" inText=" they deserve a quality education and our community’s support." footnoteIdx="1" />
          </p>
          <p>
            <strong><PopoverText isfootnoteSet={true} header="Amount YOU pay is going up" footnoteIdx="2" inText="This Is Not a New Tax" /></strong>
          </p>
          <p style={styleP}>
            This levy renews the current rate of $1.50 per $1,000 – <PopoverText isfootnoteSet={true} header="Irrevelvant" inText="the minimum rate, lower than many neighboring districts" footnoteIdx="3" />, and specifically authorized by the Legislature to fund critical school needs<PopoverText isfootnoteSet={true} header="State DOES fund critical needs" inText=" not covered by the state" footnoteIdx="4" />. You can be confident in the district’s responsible use of state, federal, and community dollars, and they can prove it. The district posts its detailed budgets every year <a href="https://www.ckschools.org/administration/budget_">https://www.ckschools.org/administration/budget_</a> information, and it has a consistent record of <PopoverText isfootnoteSet={true} header="Clean does not mean effective" inText="clean reports from the state auditor." footnoteIdx="6" />
          </p>
          <p><strong>The Levy Renewal Funds Critical Student Services and Support</strong></p>
          <p style={styleP}>
            Vote “Yes” to support our Central Kitsap students. The state only covers 1 nurse for every 12 elementary schools. <PopoverText isfootnoteSet={true} header="Can't Be Specific on the Impact it will have" inText="Local levy funds cover the difference, and also keep our class sizes small, technology up to date, and ensure that safety and security is a priority at every school.  " footnoteIdx="7" />  Every several years, the School Support Levy must be renewed. <PopoverText isfootnoteSet={true} header="It's the way it has alway been" inText="The district has had a levy in place for more than 20 years" footnoteIdx="8" /> that helps us fund programs that are not covered by the state. Without this levy, our students would face cuts to programs like arts, athletics and clubs, <PopoverText isfootnoteSet={true} header="Special Education WILL be funded" inText="special education" footnoteIdx="9" />, nurses, counselors, and libraries.
          </p>
          <p><strong>Please vote “Yes” by Tuesday, April 26th!</strong></p>
          <p style={styleP}>
            At the heart of our community, you’ll find our schools. <PopoverText
              isfootnoteSet={true}
              header="Yes, Schools do bring us together!" inText="They bring us together."
              footnoteIdx="10" /> Let’s ensure <PopoverText isfootnoteSet={true} header="Is this really continued excellence?" inText="continued excellence in Central Kitsap schools." footnoteIdx="11" />
          </p>
          {/* Rebuttle */}
          <p><strong>Rebuttal to the <span style={{ color: '#0d6efd' }} onClick={handleToAgainst}>Argument Against</span></strong></p>

          <p style={styleP}>The Against Schools Committee indicates that our <PopoverText isfootnoteset={false} header="Almost Right" inText="schools are struggling" footnoteIdx="1" />. They suggest solving it by cutting
            programs and student support until they improve. How is
            <PopoverText isfootnoteSet={false} header="We love libraries!" inText=" eliminating the library" footnoteIdx="2" /> going to improve reading skills? How
            is increasing class sizes going to improve math skills?
            This levy funds <PopoverText isfootnoteSet={false} header="Prove It" inText="needed programs and technology, sports,
            music, and extracurricular activities" footnoteIdx="3" /> which directly invest in
            student learning. A Levy failure would mean <PopoverText isfootnoteSet={false} header="How?" inText="drastic cuts" footnoteIdx="4" />.
            Please vote “Yes” for student success.</p>
          <p><a target="_blank" rel="noreferrer" href="http://www.supportckschools.info">www.supportckschools.info</a></p>
          <p><i>Argument prepared by Mark Hughes, committee chair;
            Harriette Bryant and Bruce Richards; committee members;
            committee for the measure. Further questions may be
            addressed by emailing <a href="mailto:yesckschools@gmail.com">yesckschools@gmail.com</a>.</i></p>
        </Card.Body>
      </Card>
      <Button onClick={() => props.handleCurrentTab('home')}>Return to Home</Button>
    </>
  );
}