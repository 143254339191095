import React from 'react'
import { Offcanvas, Nav, Tab, Row, Col } from 'react-bootstrap';
export default function OffCanvaseNavBar(props) {
  const handleSelection = (tabPressed) => {
    props.handleCurrentTab(tabPressed);
    props.handleClose();
  }

  return (
    <div className="m-2">
      <Offcanvas style={{ width: '10rem' }} show={props.show} onHide={props.handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Navigation</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey={props.currentTab} onSelect={handleSelection}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="home">Home</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="argFor">Argument For</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="argAgainst">Argument Against</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="resourceLinks">Resources</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

            </Row>
          </Tab.Container>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}