import React, { useEffect } from 'react';
import AdditionalCommunityVoices from './AdditionalCommunityVoices';
import CarouselComments from './CarouselComments';
export default function CommunityVoices(props) {
  useEffect(() => props.byTheNumbersReturn({ name: 'Home', tabLoad: 'home' }), [props]);

  return (
    <>
      <h2 className="p-1" style={{ textAlign: 'center', background: '#0d6efd', color: 'white' }}>Community Voices</h2>
      <p className="p-2">
        The issues surrounding the CKSD levy vote go much deeper than budgets and test scores.  To share your opposition to the levy, please complete this <a target="_blank" rel="noreferrer" href="https://forms.gle/E3ntmTiGwxgJxNeg9"> form</a>.
      </p>
      <p className="p-2"><i>(This form is for residents of CKSD only. Not all responses will be posted online. Accepted responses will be shared as written and will not be edited.)</i></p>
      <CarouselComments />
      <AdditionalCommunityVoices
        byTheNumbersReturn={props.byTheNumbersReturn}
        handleCurrentTab={props.handleCurrentTab} />
    </>
  )
}