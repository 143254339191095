import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import ByTheNumbersImage from './ByTheNumbersImage';
//import CommunityVoices from './CommunityVoices/CommunityVoices.js';
export default function ArticleOne(props) {
  useEffect(() => props.byTheNumbersReturn({ name: 'Letter to the Editor', tabLoad: 'articleOne' }), [props]);
  return (
    <>
      <Button className="m-2" onClick={() => props.handleCurrentTab('home')}>Home</Button>
      <Card className="shadow-lg m-2">
        <Card.Header>
          <Card.Title className="mt-2" style={{ textAlign: 'center' }}>Letter to the Editor</Card.Title>
          <Card.Subtitle style={{ textAlign: 'center' }}>Author: Michael Szymanski</Card.Subtitle>
          <p style={{ textAlign: 'center' }}><i>Printed with permission from the author</i></p>
        </Card.Header>
        <Card.Body>
          <article>
            <p>Dear Editors,</p>
            <p>Thank you for giving some space in your pages to the diverse opinions of taxpayers concerning the CKSD levy re-vote this month.  I see quite a bit of discussion among readers in the comments that go with many of the letters published in the Kitsap Sun.  These discussions, however, are fairly polarized, somewhat lacking in focus and devoid of appropriate context.</p>
            <p>
              For its part, CKSD Assistant Superintendent Doug Newell has done an artful job of justifying the need for the additional $20 million/year that the new levy will provide.  He focuses on things that the children won't get if the levy is not approved.  However, after attending two townhalls this week and reading their glossy pamphlets, I couldn't help but notice that the levy funds are never discussed within the context of total funding received by CKSD.  Here's the fuller context, without which voters are significantly under-informed:
            </p>
            <p>
              The 18-19 school year saw a boost of nearly $30 million per year in funding from the state as a result of the McCleary decision, which required the state (i.e., the taxpayers through another funding stream) to fully fund basic education.  It was because the state had not been fully funding basic education that localities were given the authority to run special levy elections.  So the full funding problem has been solved, yet the special levy authority remains, though it has, subsequent to McCleary, been rebranded as &quot;student enrichment funding.&quot;  It had to be rebranded, because not doing so while continuing to collect special levy revenue meant that the schools would be more than 100% funded.  The state has even firewalled basic funding by prohibiting enrichment funding from being used for basic education.
            </p>
            <p>
              In addition, CKSD, along with other school districts, has been the beneficiary of increased federal funding, nearly an additional $20 million in the current school year.  As well, state legislators in the recently completed session passed a law providing further state funding for the same items to be funded by the special levy, and has permitted the use of 2019 enrollment numbers to allocate funding in the current school year, with the effect that CKSD is receiving funds from the state for at least 300 students who are not enrolled.
            </p>
            <p>
              This additional context is not provided in these townhalls nor in the glossy pamphlets that send a message of fear that students' education needs will not be met without enrichment funding.  The undisclosed bottom line is that CKSD received total revenue of $145 million for 11,202 students in the 17-18 school year, the year before the McCleary mandates took effect.  In the current school year, CKSD is showing total revenue of $188 million for 11,134 students:  that's an increase of $43 million for 68 fewer students, nearly a 30% increase over just four years.  This information is not provided by CKSD in its townhalls
            </p>
            <p>
              In fuller context, the suggestion that schools are underfunded if they do not receive enrichment funds could not be more misleading.  The schools are flush with cash, an embarrassment of riches.
            </p>
            <p>
              A visit to CK High School and Middle School adds further context:  I don't think anyone other than a bureaucrat could argue that the schools are underfunded when seeing these beautiful facilities and campuses, which rival many community colleges.  And how is that not receiving the enrichment funds will result in "bleak" circumstances when the CKSD Superintendent received a 10% pay raise in the past year, bringing her salary up to $242,000/year (plus very generous benefits).  From an earlier Kitsap Sun article by Jeff Graham:
            </p>
            <p>
              <span><i>&quot;If you are going to cut 10% of your budget, that comes out of programs and people,&quot; said Bruce Richards, who served on Central Kitsap's school board for 19 years until recently retiring. Richards helped craft the levy support statement in the local voters' pamphlet and is hoping for a positive result later this month.</i></span>
            </p>
            <p>
              <span><i>&quot;If the levy doesn’t pass,&quot; he said. &quot;It’s going to be bleak.&quot;</i></span>
            </p>
            <p>
              Again, this is misleading.  Within the full context of revenue growth since McCleary, CKSD total revenues have grown by nearly 30% in just four years, while enrollment has declined.  The enrichment levy, around $20 million, may represent 10% of funding within a single year, but increases in total funding, $30 million from McCleary and $20 million more in federal funding in the current school year represent a $50 million increase against a possible loss of $20 million in enrichment funding.  By basic arithmetic, that's still a $30 million dollar net increase in revenue.  That's the opposite of bleak, and to characterize it as such is misleading to the point of being purposefully dishonest.
            </p>
            <p>
              {'My source for the fuller context above is from an office of our state government: '}
              <a target="_blank" rel="noreferrer" href="http://fiscal.wa.gov/K12Districts.aspx">http://fiscal.wa.gov/K12Districts.aspx</a>
            </p>
            <p>
              It will reduce the confusion and polarization surrounding this issue to better inform voters by providing them with CKSD's fuller funding context.  This is a matter of numbers, not CRT, nor sexual orientation education, etc., issues which cause us to lose focus on what is purely a dollars and cents question.  Toward that end, I have attached a chart using the state's total revenue numbers showing CKSD's total funding profile since the 16-17 school year.
            </p>
            <p>
              As there isn't a mechanism for readers to submit such information within the constraints of a 1,500 character letter to the editor, I ask that you consider an alternative means of giving more balanced and focused coverage of the fuller context of the enrichment levy.  I ask this not because I'm against taxpayer support of our public schools.  I ask this because I'm for appropriate funding of all public services that balances the burden on those that pay the freight:  taxpayers facing record high inflation and a recession later this year.
            </p>
            <p>
              CKSD provides students with awesome facilities and its teachers and administrators with compensation that competes and beats that of most school districts across the country.  The claim that a failure to approve the student enrichment levy will result in bleakness is not supported by consideration of CKSD's full funding context, nor by a visit to the gleaming public school campuses within CKSD.  I also make this request to support the mission of the Kitsap Sun, which is to provide full, fair, and balanced coverage of complex issues effecting their readership.  Absent providing the full fiscal context of CKSD re-running the student enrichment levy that failed in February, the Kitsap Sun is underserving its readers, creating the perception that you are advocating and not reporting with journalistic integrity (Jeff Graham's article on April 7 is an example of advocacy lacking full context).
            </p>
            <p>
              Thank you for your consideration.
            </p>
            <p>Michael Szymanski</p>
          </article>

        </Card.Body>
        <Card.Footer>
        </Card.Footer>
      </Card>
      <ByTheNumbersImage

        byTheNumbersImage={props.byTheNumbersImage}
        byTheNumbersReturn={props.byTheNumbersReturn}
        handleCurrentTab={props.handleCurrentTab} showButtons={false} />
      <Button className="m-2" onClick={() => props.handleCurrentTab('home')}>Home</Button>
    </>
  )
}