import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import InfoCardAgainst from './Components/InfoCardAgainst';
import InfoCardFor from './Components/InfoCardFor.js'
import OffCanvasNavBar from './Components/OffCanvasNavBar.js';
import LandingPage from './Components/LandingPage';
import InfoLinks from './Components/ResourceLinks/ResourceLinks.js';
import ArticleOne from './Components/ArticlePages/ArticleOne.js';
import ByTheNumbersImage from './Components/ArticlePages/ByTheNumbersImage';



export default function App() {

  const [currentTab, setCurrentTab] = useState('home');
  const [byTheNumbersImage, setByTheNumbersImage] = useState({ name: 'Home', tabLoad: 'home' });
  const handleCurrentTab = (currTab) => {
    setCurrentTab(currTab);
  }
  const byTheNumbersReturn = (obj) => {
    setByTheNumbersImage({ name: obj.name, tabLoad: obj.tabLoad });
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Navbar bg="primary" variant="dark">
        <div onClick={handleShow} className="display-6 bi bi-list px-2" />
        <Navbar.Brand>Vote NO CKSD Education Levy</Navbar.Brand>
      </Navbar>
      <div className="m-1">
        <OffCanvasNavBar
          show={show}
          currentTab={currentTab}
          handleCurrentTab={handleCurrentTab}
          handleClose={handleClose}
          handleShow={handleShow}
        />
        {currentTab === 'home' ? <LandingPage
          handleCurrentTab={handleCurrentTab}
          byTheNumbersReturn={byTheNumbersReturn}
        /> : ''}
        {currentTab === 'argFor' ? <InfoCardFor handleCurrentTab={handleCurrentTab} /> : ''}
        {currentTab === 'argAgainst' ? <InfoCardAgainst handleCurrentTab={handleCurrentTab} /> : ''}
        {currentTab === 'resourceLinks' ? <InfoLinks
          handleCurrentTab={handleCurrentTab}
          byTheNumbersImage={byTheNumbersImage}
          byTheNumbersReturn={byTheNumbersReturn}
        /> : ''}
        {currentTab === 'articleOne' ? <ArticleOne
          handleCurrentTab={handleCurrentTab}
          byTheNumbersImage={byTheNumbersImage}
          byTheNumbersReturn={byTheNumbersReturn}
        /> : ''}
        {currentTab === 'byTheNumbersImage' ? <ByTheNumbersImage handleCurrentTab={handleCurrentTab}
          showButtons={true}
          byTheNumbersImage={byTheNumbersImage}
          byTheNumbersReturn={byTheNumbersReturn} /> : ''}
      </div>

      <Navbar bg="primary" static="bottom" className="p-2" variant="dark">
        <Navbar.Text className="p-2">This website is paid for by Jennifer Jones, a concerned parent and resident of CKSD.  It is not affiliated with any political party or campaign.</Navbar.Text>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text className="p-2">Last Updated: 4/17/2022</Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}


