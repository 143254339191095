import React from 'react';
export default function FootnotesRebutFor(props) {
  const footnoteArr = [
    //0
    <span>No input</span>,
    //1
    <span>The school is not struggling.  The <strong>students</strong> are.  They are struggling to meet academic standards and the district has no plan to correct it.
    </span>,
    //2
    <span>No one is talking about eliminating libraries.  We are talking about measuring the effectiveness of the the current programs and student support to get the greatest return on our investment.  For example, how does having 36 library staff in our 19 schools support student achievement?
    </span>,
    //3
    <span>
      The majority of levy dollars are spent on after school extra curricular programs.  The district claims that they are necessary to keep students engaged.  However only <a target="_blank" rel="noreferrer" href="https://go.boarddocs.com/wa/ckitsd/Board.nsf/files/CBXN635E41D3/$file/BoardMeetingLevy_022322.pdf">22%</a> of K-12 students participate in after school extra curricular activities. The question comes back to: How does this spending directly impact student achievement?
    </span>,
    //4
    <span>The district cannot say what would be cut.  They also fail to advertise that recently signed <a target="_blank" rel="noreferrer" href="https://lawfilesext.leg.wa.gov/biennium/2021-22/Pdf/Bills/House%20Passed%20Legislature/1590-S.PL.pdf#page=1">HB1590</a>, allows districts to receive funding based of their 2019 enrollment numbers.  A great advantage for the district as they have <a target="_blank" rel="noreferrer" href="https://go.boarddocs.com/wa/ckitsd/Board.nsf/files/CCQSSX7423F7/$file/3.23.22%20Budget%20Update.pdf">lost 600</a> students since 2019, and do not project those students to return.  <a target="_blank" rel="noreferrer" href="https://lawfilesext.leg.wa.gov/biennium/2021-22/Pdf/Bills/House%20Passed%20Legislature/1664-S2.PL.pdf#page=1">HB 1664</a> allows more funding for counselors, nurses, social workers, and psychologists.  The state has added a <a target="_blank" rel="noreferrer" href="https://go.boarddocs.com/wa/ckitsd/Board.nsf/files/CCQSSX7423F7/$file/3.23.22%20Budget%20Update.pdf
    ">Transportation Safety Net</a> to help offset costs for "special passengers." The state has increased their funding for <a target="_blank" rel="noreferrer" href="https://go.boarddocs.com/wa/ckitsd/Board.nsf/files/CCQSSX7423F7/$file/3.23.22%20Budget%20Update.pdf
    ">Materials, Supplies, and Operating Cost</a> 7% overall, and 23% specifically for technology. Not to mention, the district still has $10M in <a target="_blank" rel="noreferrer" href="https://app.smartsheet.com/b/publish?EQBCT=d5e01460cb7945e1a6b676c38f3cb6e6">ESSER</a> funds available through 2024.
    </span>


  ];
  return (
    <>
      {footnoteArr[props.idx] ? footnoteArr[props.idx] : footnoteArr[0]}
    </>
  )
}