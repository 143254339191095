import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import JsonToCard from '../../HelperFunctions/JSONtoCard.js';
import linkData from '../../JSONData/resources.json';
import MultiLinkCards from './MultiLinkCards.js';

export default function InformationLinksPage(props) {
  const handleClick = () => props.handleCurrentTab('byTheNumbersImage');
  useEffect(() => props.byTheNumbersReturn({ name: 'Resources', tabLoad: 'resourceLinks' }), [props]);
  return (
    <>
      <h1>Resources</h1>
      <JsonToCard data={linkData} />
      <Card className="shadow-lg">
        <Card.Title onClick={handleClick} className="m-2 mx-3" style={{ color: '#0066CC' }}>CKSD By The Numbers</Card.Title>
        <Card.Body>This graph based off information from <a target="_blank" rel="noreferrer" href="http://fiscal.wa.gov/K12Districts.aspx">http://fiscal.wa.gov/K12Districts.aspx</a> shows a 30% increase in funding in four years despite fewer students.
        </Card.Body>
      </Card>
      <MultiLinkCards />
    </>
  )
}
//<a target="_blank" rel="noreferrer" href={data}>{data}</a>