import React from 'react'
import { Card, Table } from 'react-bootstrap'
export default function AdditionalCommunityVoices(props) {
  const handleArticleClick = () => {
    props.byTheNumbersReturn({ name: 'Home', tabLoad: 'home' });
    props.handleCurrentTab('articleOne');
  }

  return (

    <Card className="my-2 shadow">
      <Card.Title as="h3" style={{ textAlign: 'center', background: '#0d6efd', color: 'white' }}>Additional Community Voices</Card.Title>
      <Card.Body>
        <Table>
          <thead>
            <tr>
              <th>Link</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><a target="_blank" rel="noreferrer" href="https://www.kitsapsun.com/story/opinion/readers/2022/04/14/im-voting-against-levy-send-message/7322579001/?fbclid=IwAR3kJ6MfQOUvr4YUnezFZ5yUiqyufexMCO3LT0KbpEl1mNq007AR3Dr7iL0">Kitsap Sun Editorial</a></td>
              <td>CKSD community member shares why she is voting No.</td>
            </tr>
            <tr>
              <td><a target="_blank" rel="noreferrer" href="https://bainbridgeislandrepublicanwomen.com/">Bainbridge Island Republican Women</a></td>
              <td>Scroll toward the bottom to see that even Bainbridge Island is closely watching what is happening in CKSD.</td>
            </tr>
            <tr>
              <td style={{ color: '#0066CC' }} onClick={() => handleArticleClick()}><u>A Letter to the Editor</u></td>
              <td>CKSD community member shares why he is voting no.  The editorial is currently awaiting approval to be published.</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}