import React from 'react';
import { Card, Button, Table, Row } from 'react-bootstrap';
import CommunityVoices from './CommunityVoices/CommunityVoices.js';
export default function LandingPage(props) {
  const handleResourceButton = () => props.handleCurrentTab('resourceLinks');
  return (
    <>
      <Card>
        <Card.Title className="p-2">Welcome to <b>Vote No on the CKSD Eduction Levy</b> Resource site.</Card.Title>
        <Card.Body>
          <p> This site was started to provide additional information with respect to voting <strong>NO</strong> to the proposed Central Kitsap School District Enrichment Levy. The information provided is intended to give you the facts and the links to make an informed decision.</p>

          <p>For information on <a href="https://www.kitsapgov.com/auditor/Pages/Voter_Registration.aspx" target="_blank" rel="noreferrer">voter registration</a> and <a href="https://www.kitsapgov.com/auditor/Pages/kitsap-county-voting-locations.aspx" target="_blank" rel="noreferrer">ballot drop off locations</a>, please visit the <a href="https://www.kitsapgov.com/auditor/Pages/default.aspx" target="_blank" rel="noreferrer">Kitsap County Auditor's Office</a>.  Please call the Auditor's office at (360) 337-7128 to learn the estimated cost to tax payers in running this special election at the request of <a target="_blank" rel="noreferrer" href="https://www.ckschools.org/administration/c_k_s_d_school_board/school_board">CKSD School Board</a></p>
          <h4>VOTE NO ON APRIL 26th</h4>
          <Card.Title><u><strong>EVERY VOTE COUNTS</strong></u></Card.Title>
          <p>Follow ballot instructions <span><b>exactly</b></span></p>
          <p>To check your ballot status, request a new ballot, or vote online, visit the <a target="_blank" rel="noreferrer" href="https://voter.votewa.gov/WhereToVote.aspx">Voter Portal</a></p>
        </Card.Body>
      </Card>
      <Row xs={2}>
        <Card>
          <Card.Body>
            <Button rel="noreferrer" target="_blank" href="https://www.kitsapgov.com/auditor/Documents/LVP.pdf">Voter Pamphlet</Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Button rel="noreferrer" target="_blank" href="https://go.boarddocs.com/wa/ckitsd/Board.nsf/files/CBX9CT7646CC/$file/Resolution%2011-21-22%20-%20Replacement%20Enrichment%20Levy%20-%20Central%20Kitsap%20(Feb%202022).pdf">Resolution 11-21-22</Button>
          </Card.Body>
        </Card>
      </Row>
      <Card>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Argument For</th>
              <th>Argument Against</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>The argument for passing of the levy.  The argument is highlighted to provide additional context</td>
              <td>The argument for rejection of the levy.  Text is hyper-linked for additional information</td>
            </tr>
            <tr>
              <td>
                <Button onClick={() => props.handleCurrentTab('argFor')}>For</Button>
              </td>
              <td >
                <Button onClick={() => props.handleCurrentTab('argAgainst')}>Against</Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>
      <Card>
        <Card.Body>
          <Button onClick={handleResourceButton}>Links to All Referenced Information</Button>
        </Card.Body>
      </Card>
      <CommunityVoices
        handleCurrentTab={props.handleCurrentTab}
        byTheNumbersReturn={props.byTheNumbersReturn}
      />


    </>
  )
}