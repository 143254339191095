import { Button } from 'react-bootstrap';
import React from 'react';
import Card from 'react-bootstrap/Card';
export default function InfoCardAgainst(props) {

  const handleToFor = () => props.handleCurrentTab('argFor');
  const styleP = { textIndent: '.5rem' };
  return (
    <>
      <Card className="m-2">
        <Card.Title className="p-2" style={{ border: '1px', background: 'lightBlue' }}>Argument Against the Measure</Card.Title>
        <Card.Body>
          <p><strong>No Transparency, No Outcomes, No Answers</strong></p>
          <p style={styleP}>
            In the two months since the school enrichment levy failed, requests for fiscal responsibility through transparency and measurable outcomes specific to levy funds have not been answered as achievement levels have fallen.</p>
          <p style={styleP}>In the <a target="_blank" rel="noreferrer" href="https://washingtonstatereportcard.ospi.k12.wa.us/ReportCard/ViewSchoolOrDistrict/100038">state's recent report card</a> for CKSD, students meeting standards dropped to <strong>32%</strong> in math and <strong>59%</strong> in reading.  CKSD is not accomplishing its number one job of educating our students, and making no visible attempt to remedy it.  Failing scores are not addressed in board meetings or <a href="https://go.boarddocs.com/wa/ckitsd/Board.nsf/files/CBFMPY5B2CAE/$file/2020-21%20Annual%20Report%20Presentation.pdf" target="_blank" rel="noreferrer">annual reports</a>.  Throwing more money at the problem will not help.  Per pupil spending is at an all-time high.  (<a target="_blank" rel="noreferrer" href="https://washingtonstatereportcard.ospi.k12.wa.us/ReportCard/ViewSchoolOrDistrict/100038">State report card</a> & <a target="_blank" rel="noreferrer" href="https://www.k12.wa.us/sites/default/files/public/safs/misc/budprep18/multi-yeartoolversion7forposting.xlsx">multi-year tool</a>)   The district cannot ask for $20 million a year without a specific plan and measurable goals outlining how additional funds will support student learning.</p>
          <p style={styleP}> Accountability of previous levy funds cannot be provided as there is not a separate line item in the budget identifying levy fund income and expenditures.  As responsible taxpayers, we have the right to demand and expect a return on our additional investment in CKSD. We have the right to question the public institutions we support.  CKSD has an obligation to answer those questions and responsibly use the money entrusted to them for the education of our children.</p>
          <p style={styleP}>CKSD is not addressing community concerns as to how the levy will increase student achievement.  We ask you to vote no until measurable outcomes become the district's priority, transparency is welcomed, and we see positive verifiable results in student achievement.</p>
          <p><strong>Rebuttal to the <span style={{ color: '#0d6efd' }} onClick={handleToFor}>Argument For</span></strong></p>
          <p style={styleP}>CKSD redefines “excellence” as 32% proficiency.
            <a target="_blank" rel="noreferrer" href="https://washingtonstatereportcard.ospi.k12.wa.us/ReportCard/ViewSchoolOrDistrict/100038">https://washingtonstatereportcard.ospi.k12.wa.us/ReportCard/ViewSchoolOrDistrict/100038 </a>
            CKSD’s clean
            audit means income equals expenses, not expenditures
            equal student achievement. CKSD claims taxes won’t
            increase. However, $18M to $20M per year is an increase.
            CKSD hasn’t stated how a failed levy will impact students.
            Fact, the state funds all services to operate a school and
            reach academic standards, including fully funding special
            education (RCW28A.150.260 & 28A.150.392). CKSD can’t
            show the value of the levy; vote no. </p>
          <p><i>Argument prepared by Jennifer Jones, committee chair;
            Lisa Mischenko and Damon Williams; committee members;
            committee against the measure. Further questions may be
            addressed by emailing <a href="mailto:votenolevyck@gmail.com">votenolevyck@gmail.com</a> or
            visiting <a href="https://www.votenolevyck.org/" target="_blank" rel="noreferrer">votenolevyck.org</a></i></p>
        </Card.Body>
      </Card>
      <Button onClick={() => props.handleCurrentTab('home')}>Return to Home</Button>
    </>
  );
}