
import React, { useEffect } from 'react'
import { Button, Card } from 'react-bootstrap'
export default function ByTheNumbersImage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const name = (props.byTheNumbersImage.name) ? props.byTheNumbersImage.name : 'No Name';
  const tab = (props.byTheNumbersImage.tabLoad) ? props.byTheNumbersImage.tabLoad : 'home';
  return (
    <>
      {(props.showButtons) ? <Button className="m-2" onClick={() => props.handleCurrentTab(tab)}>{name}</Button> : ''}
      <Card className="shadow-lg m-2">
        {!props.showButtons ? <Card.Img onClick={() => props.handleCurrentTab('byTheNumbersImage')} src="./assets/CKSD-Funding-By-The-Numbers.png" /> :
          <Card.Img src="./assets/CKSD-Funding-By-The-Numbers.png" />
        }

      </Card>
      {(props.showButtons) ? <Button className="m-2" onClick={() => props.handleCurrentTab(tab)}>{name}</Button> : ''}
    </>
  );
}