import React from 'react';
export default function Footnotes(props) {
  const footnoteArr = [
    //0
    <span>No input</span>,
    //1
    <span>Our students <b>do</b> deserve a quality education and the community’s support.  As the community, it is our job to ensure the district is delivering a quality education to <b>all students</b> and being <b>good stewards</b> with our tax dollars."</span>,
    //2
    <span>It is not a new tax and the rate is staying the same.  However, the amount you pay will go up because your taxes are based on your property value.  Property values have risen tremendous since that last levy was passed.  The district even admits it is asking for more money.  The last levy was for <b>$18M</b> per year; this one is <b>$20M</b> per year.</span>,
    //3
    <span>This is irrelevant. It still impacts our pocketbooks.</span>,
    //4
    <span>The state funds all critical school needs, and is required to amply fund basic education in the <a target="_blank" rel="noreferrer" href="https://www.washingtonea.org/advocacy/mccleary-school-funding/?fbclid=IwAR0WbCb0zcwR6Txi8b2wqnpINV9GQfUbB9Mz0qwx1TZM6YLKy1qb_OXv3QE">McCleary Decision</a>. <a target="_blank" rel="noreferrer" href="https://app.leg.wa.gov/rcw/default.aspx?cite=28A.150.260">RCW28A.150.260</a> & <a target="_blank" rel="noreferrer" href="https://app.leg.wa.gov/RCW/default.aspx?cite=28A.150.392">28A.150.392</a></span>,
    //5
    <span>Budgets are not the same as actual expenditures. If you look at the budget though you will see since last school year, CK has lost 35 certificated teaching staff, 30 classified staff in principal office admin, and 2 in safety (something CK says the levy helps pay for). CK has gained 10 certificated staff in health, 2 in Instructional Professional Development, 21 classified staff in teaching, 4 in Professional Learning- State, and 4 in Central Administration for Supervision-Instruction.  How are these new hires helping our students in the classroom?
      Since last school year, the budget for Central Admin Supervision & Instruction is up over 1M. Central Admin "supervision of building" has doubled to over 800k, and the principals’ budget is up 600k.
      {/* UPDATE LINK */}
      <a target="_blank" rel="noreferrer" href="https://hostedreports.ospi.k12.wa.us/api/0/Document/Download/70485">Here</a> are the actual expenditures for the 2020-2021 school year.
    </span>,
    //6
    <span>
      A “clean audit” simply means all monies are accounted for.  It does not show monies were spent effectively to reach outcomes.  What we need to know is the academic return on investment, and
      <a href="https://www.dmgroupk12.com/writable/documents/Spending-Money-Wisely-01-Calculating-Academic-Return-on-Investment.pdf" target="_blank" rel="noreferrer"> it has been done </a>in other states.
    </span>,
    //7
    <span>
      The district is having a hard time saying how a failed levy will impact students other than extra-curricular activities.  On the note of small class sizes, in state funding, Kindergarten class size is 17 and high school is 27. (<a href="https://app.leg.wa.gov/rcw/default.aspx?cite=28A.150.260" target="_blank" rel="noreferrer">RCW28A.150.260</a> section 4) Those seem like very reasonable class sizes, and CKSD enrollment is down 600 students. (<a target="_blank" rel="noreferrer" href="https://go.boarddocs.com/wa/ckitsd/Board.nsf/files/CCQSSX7423F7/$file/3.23.22%20Budget%20Update.pdf">Budget</a> page 7)
    </span>,
    //8
    <span>This statement makes the district sound like a bully. “Give us your money because you have in the past.”  “Because this how we’ve always done it” shows a lack of critical and reflective thinking.  It is time we stand up to the bully and demand accountability on our investment.
    </span>,
    //9
    <span>Special Education must be fully funded by law, and the state will ensure it is.  <a target="_blank" rel="noreferrer" href="https://app.leg.wa.gov/RCW/default.aspx?cite=28A.150.392">RCW 28A.150.392</a>.  Recently passed and signed <a target="_blank" rel="noreferrer" href="https://lawfilesext.leg.wa.gov/biennium/2021-22/Pdf/Bills/House Passed Legislature/1664-S2.PL.pdf?q=20220316130632">HB1664</a> allocates more funding for counselors, nurses, social workers, and psychologists.
    </span>,
    //10
    <span>
      Schools do bring together communities. As the community, it is our job to ensure the district is delivering a quality education to all students and being good stewards with our tax dollars.
    </span>,
    //11
    <span>
      To see CKSD’s definition of “excellence,” check out their latest test scores and more on their <a target="_blank" rel="noreferrer" href="https://washingtonstatereportcard.ospi.k12.wa.us/ReportCard/ViewSchoolOrDistrict/100038">state report card</a>.
    </span>
  ]
  return (
    <>
      {footnoteArr[props.idx] ? footnoteArr[props.idx] : footnoteArr[0]}
    </>
  )

}