import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Footnotes from './FootnotesFor';
import FootnotesRebutFor from './FootnotesRebutFor';
export default function PopoverText(props) {
  const popoverText = (
    <Popover id="popover-basic" >
      <Popover.Header as="h3">{props.header ? props.header : 'No Header'}</Popover.Header>
      <Popover.Body>
        {(props.isfootnoteSet === true) ?
          <Footnotes idx={props.footnoteIdx} /> :
          <FootnotesRebutFor idx={props.footnoteIdx} />
        }
      </Popover.Body>
    </Popover>


  );
  return (
    <OverlayTrigger rootClose="true" trigger="click" placement="bottom" overlay={popoverText}>
      <span style={{ color: 'purple', fontWeight: 'bolder' }}>{props.inText ? props.inText : 'No text to click'}</span>
    </OverlayTrigger>
  );
}