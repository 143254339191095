import React from 'react';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
export default function MultiLinkCards() {
  return (
    <>
      <Card className="my-2 shadow">
        <Card.Title><Nav.Link style={{ color: 'black' }}>Academic Return on Investment (A-ROI)</Nav.Link></Card.Title>
        <Card.Body>
          A-ROI have been done around the country to help school districts streamline their budgets and ensure academic goals are being met.  Read more at the following links. CKSD does not currently have any A-ROI systems.  If you think CKSD could benefit from them, please contact the <a target="_blank" rel="noreferrer" href="https://www.ckschools.org/cms/One.aspx?portalId=11175&pageId=34524">district office</a> and <a target="_blank" rel="noreferrer" href="https://www.ckschools.org/administration/c_k_s_d_school_board/school_board">board</a>.
          <Nav.Link target="_blank" href="https://www.dmgroupk12.com/writable/documents/Spending-Money-Wisely-01-Calculating-Academic-Return-on-Investment.pdf">District Management Council</Nav.Link>
          <Nav.Link target="_blank" href="https://www.gfoa.org/materials/academic-return-on-investment-foundations-and-smart">Government Finance Officers Association</Nav.Link>
          <Nav.Link target="_blank" href="https://ecragroup.com/roi/">ECRA Group</Nav.Link>
        </Card.Body>
      </Card>
      <Card className="my-2 shadow">
        <Card.Title><Nav.Link style={{ color: 'black' }}>Elementary and Secondary School Emergency Relief Funding Information</Nav.Link></Card.Title>
        <Card.Body>
          ESSER funds have been issued by the federal government three times (ESSER I, ESSER II, ESSER III).  Each state must submit a plan to receive funds.  Each district has an allotted amount to spend by 2024.
          <Nav.Link target="_blank" href="https://oese.ed.gov/files/2021/11/WA-ARP-ESSER-State-Plan-Final.pdf">US Department of Education ESSER Plan</Nav.Link>
          <Nav.Link target="_blank" href="https://www.k12.wa.us/sites/default/files/public/communications/ESSER-GEER-FAQs.pdf">US Department of Education ESSER FAQ</Nav.Link>
          <Nav.Link target="_blank" href="https://www.k12.wa.us/sites/default/files/public/bulletinsmemos/bulletins2021/B005-21.pdf">OSPI ESSER fund guidance</Nav.Link>
        </Card.Body>
      </Card>
      <Card className="my-2 shadow">
        <Card.Title><Nav.Link style={{ color: 'black' }}>McCleary Decision</Nav.Link></Card.Title>
        <Card.Body>The McCleary decision in 2012 and its enforcement in 2018 have dramatically changed WA public school funding.  It requires the state to amply fund basic education.
          <Nav.Link target="_blank" href="https://www.washingtonea.org/advocacy/mccleary-school-funding/?fbclid=IwAR0WbCb0zcwR6Txi8b2wqnpINV9GQfUbB9Mz0qwx1TZM6YLKy1qb_OXv3QE">WEA Overview</Nav.Link>
          <Nav.Link target="_blank" href="https://waschoolexcellence.org/news-and-resources/faqs/">McCleary FAQ's</Nav.Link>
          <Nav.Link target="_blank" href="https://www.courts.wa.gov/opinions/pdf/843627.opn.pdf">WA Supreme Court Ruling</Nav.Link>
        </Card.Body>
      </Card>
    </>
  )
}
