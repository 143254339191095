import { Carousel } from 'react-bootstrap';
import React from 'react'
import { Card } from 'react-bootstrap'
import CVData from '../../JSONData/communityvoices.json';

export default function CarouselComments() {
  return (
    <Carousel variant="dark" interval={null}>
      {CVData.map((item, idx) =>

        <Carousel.Item key={`commVoice-carouselItem-${idx}`}>
          <Card style={{ background: '#e2e2e2' }}>
            <figure className="p-3 text-center">
              <div className="mx-5">
                <blockquote className="blockquote">{item.Comment}.</blockquote>
                {/* <figcaption class=" blockquote-footer">
                  Rachael <cite title="Source Title">Parent</cite>
                </figcaption> */}
                <h5>- {item.Name}, <i>{item.Title}</i></h5>
              </div>
            </figure>
          </Card>
        </Carousel.Item>
      )}
    </Carousel>
  )
}