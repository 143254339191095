import React from 'react'
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';


export default function CSVReader(props) {


  //This Components is expecting props.data {prop1 = Card Title, prop2 = Link, prop3 = description, }

  return (
    <>
      {(props.data.length > 0) ?
        props.data.map((cardRow, idx) =>
          <Card key={`card-link-${idx}`} className="my-2 shadow">
            <Card.Title><Nav.Link target="_blank" href={Object.values(cardRow)[1]}>{Object.values(cardRow)[0]}</Nav.Link></Card.Title>
            <Card.Body>
              {Object.values(cardRow)[2]}
            </Card.Body>
          </Card>

        ) : ''
      }
    </>
  )
}


